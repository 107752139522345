// eslint-disable ts/no-unused-expressions
/**
 * @description 登录、获取用户信息、退出登录、清除token逻辑，不建议修改
 */
// import { useAclStore } from './acl/acl/acl'
// import { useRoutesStore } from './routes'
// import { useSettingsStore } from './settings'
import cookie from 'js-cookie'
import { setStorage } from '@sjzy/utils'
import { jwtDecode } from 'jwt-decode'
import { isArray } from 'lodash-es'

// import useTabsStore from './tabs'

// import { resetRouter } from '@/utils/routes'
import { getToken } from '@/utils/token'

// import { isArray, isString } from '@/utils/validate'
import type { UserModuleType } from '@/types/store'
import { OAuthGetSysTokenPOST } from '@/api/user-oAuth/OAuth'
import { AuthGetUserPermissionLinkCodePOST } from '@/api/user-auth/Auth'
import { RoleGetRoleSystemInfoByUserIdGET } from '@/api/role/Role'
import { DataAuthGetListByUserIdGET } from '@/api/dataAuth/DataAuth'
import useUserProfile from '@/composables/feishu/useUserProfile'
import { OAuthRefreshTokenPOST } from '@/api/other'
import { UserIsRoleAdminPOST } from '@/api/user-user/User'

function parseQuery() {
  const queryStr = window.location.href.split('?')
  const paramsObj: Indexable = {}
  if (queryStr.length > 1) {
    const paramsArr = queryStr[1].split('&')

    paramsArr.forEach((param) => {
      const [key, value] = param.split('=')
      paramsObj[key] = value
    })
  }

  return paramsObj
}

let isTicktSign = true
export const useUserStore = defineStore('crm-user', {
  state: (): UserModuleType => ({
    token: getToken() as string,
    username: '游客',
    userId: 0,
    jwtUserId: '',
    subSysId: 0,
    menu: ['/403', '/login', '/register', '/slogin', '/tlogin', '/callback', '/404', '/index', '/'],
    sysBusinessType: 0,
    sysBusinessKey: '',
    businessSubSystemId: 0,
    subSystemObj: [],
    systemData: [],
    isOpenBalance: false,
    employeeId: 0,
    businessId: 0,
    companyId: 0,
    departmentId: 0,
    avatar: 'https://i.gtimg.cn/club/item/face/img/2/15922_100.gif',
    roles: [],
    isRoleAdmin: false,
    dataAuth: [],
    businessRoleEnums: [],
  }),
  persist: true,
  getters: {
    getToken: state => state.token,
    getUsername: state => state.username,
    getAvatar: state => state.avatar,
    getUserId: state => state.userId,
    getSubSysId: state => state.subSysId,
    getMenu: state => state.menu,
    getSysBusinessType: (state) => {
      const obj = parseQuery()
      if (obj.sysBusinessType) {
        return obj.sysBusinessType
      }
      else {
        return state.sysBusinessType
      }
    },
    getBusinessSubSystemId: (state) => {
      const obj = parseQuery()
      if (obj.type === 'iframe' || obj.subSysId) {
        return Number(obj.subSysId)
      }
      else {
        return state.businessSubSystemId
      }
    },
    getsysBusinessKey: (state) => {
      return state.sysBusinessKey
    },
    getIsOpenBalance: state => state.isOpenBalance,
    getEmployeeId: state => state.employeeId,
    getDepartmentId: state => state.departmentId,
    getCompanyId: state => state.companyId,
    getBusinessId: state => state.businessId,
    getSysBusinessKey: (state) => {
      const obj = parseQuery()
      if (obj.type === 'iframe') {
        return state.subSystemObj.find(i => i.id === Number(obj.subSysId))?.key
      }
      else if (window?.__MICRO_APP_ENVIRONMENT__) {
        return state.subSystemObj.find(i => i.id === Number(getMicroData()?.systemId))?.key
      }
      else {
        return state.sysBusinessKey
      }
    },
    getRoles: state => state.roles,
    getIsRoleAdmin: state => state.isRoleAdmin,
    getDataAuth: state => state.dataAuth,
    getBusinessRoleEnums: state => state.businessRoleEnums,
  },
  actions: {
    /**
     * @description 设置菜单
     * @param {*} menu
     */
    setMenu(menu: any[]) {
      this.menu = [
        '/403',
        '/login',
        '/register',
        '/slogin',
        '/tlogin',
        '/callback',
        '/404',
        '/index',
        '/',
      ]
      this.menu = this.menu.concat(menu)
    },
    /**
     * @description 设置token
     * @param {*} token
     */
    setToken(token: string) {
      this.token = token
      // setToken(token)
    },
    tokenSet(token?: string, type = 'token') {
      const mode = import.meta.env.MODE
      const name = `${mode}_` + `gtf_${type}`
      this.setCookie(name, token)
    },
    setCookie(key: string, value?: string) {
      const mode = import.meta.env.MODE
      const name = `${mode}_${key}`
      const domainc = import.meta.env.VITE_COOKIE_DOMAIN
      const obj = { domain: domainc, path: '/' }
      cookie.set(name, value || '', obj)
      // 再设置token或刷新token时候重新解析赋值
      if (key === 'gtf_token') {
        const decoded: { user_id: string } = jwtDecode(value || '')
        this.jwtUserId = decoded.user_id
        this.setCookie('userId', decoded.user_id)
      }
    },
    setSystemData(obj: any) {
      this.systemData = obj
    },
    setSystemObj(obj: any) {
      this.subSystemObj = obj
    },
    setSysBusinessKey(sysBusinessKey: string) {
      this.sysBusinessKey = sysBusinessKey
    },
    setCompanyId(companyId: number) {
      this.companyId = companyId
    },
    setDepartmentId(departmentId: number) {
      this.departmentId = departmentId
    },
    setEmployeeId(employeeId: number) {
      this.employeeId = employeeId
    },
    setBusinessId(businessId: number) {
      this.businessId = businessId
    },
    /**
     * @description 设置业务类型
     * @param {*} sysBusinessType
     */
    setSysBusinessType(sysBusinessType: number) {
      this.sysBusinessType = sysBusinessType
    },
    /**
     * @description 设置子系统id
     * @param {*} businessSubSystemId
     */
    setBusinessSubSystemId(businessSubSystemId: number) {
      console.log(businessSubSystemId, 'businessSubSystemId')
      if (window?.__MICRO_APP_ENVIRONMENT__) {
        this.businessSubSystemId = getMicroData()?.systemId
      }
      else {
        this.businessSubSystemId = businessSubSystemId
      }
    },
    setIsOpenBalance(isOpenBalance: boolean) {
      this.isOpenBalance = isOpenBalance
    },
    /**
     * @description 设置用户名
     * @param {*} username
     */
    setUsername(username: string) {
      this.username = username
    },
    /**
     * @description 设置用户id
     * @param {*} userId
     */
    setUserId(userId: string | number) {
      this.userId = userId
    },
    setSubSysId(subSysId: string | number) {
      this.subSysId = subSysId
    },
    /**
     * @description 设置头像
     * @param {*} avatar
     */
    setAvatar(avatar: string) {
      this.avatar = avatar
    },
    /**
     * @description 设置权限
     * @param {*} val
     */
    setPermission(val: string[]) {
      setStorage('permission', val)
    },
    /** 设置业务角色 */
    setBusinessRoleEnums(businessRoleEnums: number[]) {
      this.businessRoleEnums = businessRoleEnums
    },
    /**
     * 判断是否存在一种或者多种角色
     */
    isHasRoles(roles?: number[]) {
      const businessRoleEnums = this.businessRoleEnums
      if (roles && isArray(roles) && businessRoleEnums)
        return roles.some((role: any) => businessRoleEnums.includes(role))

      return false
    },
    /** 获取新token */
    async refreshToken() {
      const mode = import.meta.env.MODE
      const xtokenCookie = cookie.get(`${mode}_gtf_x_token`)
      await OAuthRefreshTokenPOST({
        headers: {
          'x-Authorization': xtokenCookie,
        },
      })
    },
    /**
     * @description 获取用户信息接口 这个接口非常非常重要，如果没有明确底层前逻辑禁止修改此方法，错误的修改可能造成整个框架无法正常使用
     */
    async getUserInfo() {
      const useSettings = useSettingsStore()
      const { getLanguage } = storeToRefs(useSettings)
      // const mode = import.meta.env.MODE
      // const token = cookie.get(`${mode}_gtf_token`)
      const { code, data } = await OAuthGetSysTokenPOST({})
      if (code === '200') {
        // 监听cookie变化 兼容性地址 https://developer.mozilla.org/en-US/docs/Web/API/CookieStore/change_event#browser_compatibility

        window.cookieStore
        && window.cookieStore.addEventListener('change', (event: any) => {
          if (this.jwtUserId) {
            const mode = import.meta.env.MODE
            const name = `${mode}_userId`
            event.changed.forEach(async (item: Indexable) => {
              if (name === item.name && +this.jwtUserId !== +item.value) {
                const routesStore = useRoutesStore()
                const tabsStore = useTabsStore()
                tabsStore.delAllVisitedRoutes()
                routesStore.clearRoutes()
                window.location.reload()
              }
            })
          }
        })
        // 解析 token获取userid 存储起来
        const mode = import.meta.env.MODE
        const token = cookie.get(`${mode}_gtf_token`) || ''
        const decoded: { user_id: string } = jwtDecode(token || '')
        this.jwtUserId = decoded.user_id
        this.setCookie('userId', decoded.user_id)
        this.setUsername(
          getLanguage.value === 'zh-CN' ? data.userInfo!.name! : data.userInfo!.enName!,
        )
        this.setUserId(data.userInfo!.id!)
        this.setEmployeeId(data.userInfo!.employeeId!)
        this.setToken(data.token as string)
        if (isTicktSign) {
          isTicktSign = false
          setTimeout(async () => {
            const { getTicktSign } = useUserProfile()

            const data = await getTicktSign()
            if (!data) {
              isTicktSign = true
            }
          }, 100)
        }
      }
    },

    async getUserRoles() {
      const { data } = await RoleGetRoleSystemInfoByUserIdGET({
        systemId: this.getSubSysId as number,
        userId: this.getUserId as number,
      })
      this.roles = data.map(item => item.code) as string[]
      const isAdmin = this.roles.includes('Admin')
      // isAdmin
      localStorage.setItem('isAdmin', isAdmin ? 'true' : 'false')
    },
    async getUserIsRoleAdmin() {
      const { data } = await UserIsRoleAdminPOST({
        systemId: this.getSubSysId as number,
        userId: this.getUserId as number,
      } as API.UserIsRoleAdminPOSTParams)
      this.isRoleAdmin = !!data
      localStorage.setItem('isRoleAdmin', data ? 'true' : 'false')
    },
    async getUserDataAuth() {
      const { data } = await DataAuthGetListByUserIdGET({
        systemId: this.getSubSysId as number,
        userId: this.getUserId as number,
      })
      this.dataAuth = data.map(item => item.code) as string[]
    },
    /**
     * @description 退出登录
     */
    async logout() {
      // await logout()
      await this.resetAll()
      // location.reload()
    },
    /**
     * @description 重置token、roles、permission、router、tabsBar等
     */
    async resetAll() {
      this.setSysBusinessType(0)
      this.setBusinessSubSystemId(0)
      const mode = import.meta.env.MODE
      cookie.remove(`${mode}_gtf_token`, {
        domain: import.meta.env.VITE_COOKIE_DOMAIN,
        path: '/',
      })
      cookie.remove(`${mode}_gtf_x_token`, {
        domain: import.meta.env.VITE_COOKIE_DOMAIN,
        path: '/',
      })
      cookie.remove(`${mode}_timespan`, {
        domain: import.meta.env.VITE_COOKIE_DOMAIN,
        path: '/',
      })
      cookie.remove(`${mode}_expired`, {
        domain: import.meta.env.VITE_COOKIE_DOMAIN,
        path: '/',
      })
      // remove触发不了监听
      this.setCookie('userId', '')
      const routesStore = useRoutesStore()
      const tabsStore = useTabsStore()
      tabsStore.delAllVisitedRoutes()
      routesStore.clearRoutes()
      localStorage.removeItem('feishuStroe')
      // await resetRouter()
      // removeToken()
    },
    /**
     * @description 获取用户拥有权限
     * @return void
     */
    async getUserPermission() {
      const { code, data } = await AuthGetUserPermissionLinkCodePOST({})
      if (code === '200') {
        this.setPermission(data)
      }
    },
  },
})

export default useUserStore
