import type { Router } from 'vue-router'
import type { TabsModuleType } from '@/types/store'

/**
 * 与基座进行数据交互 - 获取
 * @param router
 */
export function getMicroData(): {
  /** 主应用的系统id 一般作为业务系统id使用 */
  systemId: number
  /** 主应用的路由Router 可进行主应用的跳转 */
  mainAppRouter: Router
  tabsStore: TabsModuleType
  /** 主应用修改标题的事件 */
  setTitle?: (title: any) => void
  delVisitedRoute: (str: string) => void
  /** 是否不加载框架的全局样式 */
  isLoadCommonCss: boolean
} {
  const obj = window.rawWindow[`eventCenterFor${window?.__MICRO_APP_NAME__}`].getData()
  return {
    isLoadCommonCss: true,
    ...obj,
  }
}
/**
 * 与基座进行数据交互 - 发送
 * @param router
 */
export function sendMicroData(data: any) {
  window.rawWindow[`eventCenterFor${window?.__MICRO_APP_NAME__}`].dispatch(data)
}
